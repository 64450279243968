import React, { useState } from "react"
import useInfiniteScroll from "./useInfiniteScroll";
import Article from "./Article"

function ArticleList(props) {
	const [articles, setArticles] = useState(props.articles);
	const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);

  function fetchMoreListItems() {
  	setTimeout(() => {
	  	let url = $('#more-articles').attr('href');

	  	$.getJSON(url).done(function( result, textStatus ) {
	        $('#more-articles').text("More Articles..");

	        if (result.articles && result.articles.length > 0) {
	        	$('#more-articles').show();
	        	setArticles(prevState => ([...prevState, ...result.articles]));
	        	let link =`/articles.json?append=true&page=${result.page}`
	        	if(result.tag) { link += `&tag=${result.tag}` }
	        	$('#more-articles').attr('href',link);
	        }	else {
	        	$('#more-articles').hide();
	        }
	      })
	      .fail(function( jqxhr, settings, exception ) {
	        $('#more-articles').text("Fail Failed loading articles");
	    });

	    setIsFetching(false);
	  }, 100);
  }

  return (
  	<div>
  		{
  			articles.map(function(article){
  				return(
  					<Article article={article} key={article.id}/>
  				)
  			})
  		}
  	</div>
  );
}

export default ArticleList
