import React from "react"

function Article({article}) {
	let article_path = `articles/${article.id}-${article.title.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'')}`
  return (
	  <article className="dt-post sr-animated" data-sr-id="1">
	    <header className="dt-entry-header">
	      <div className="dt-entry-featured dt-format-standard image-spin">
	      	<a href={article_path} className= "dt-read-more">
						<img alt={article.place} src={require(`../../assets/images/static/blog/${article.place}/${article.image_path}`)}/>
					</a>
	      </div>

	      <h3 className="dt-title">
	      	<a href={article_path} className= "dt-read-more">
						{article.title}
					</a>
	      </h3>
	    </header>

	    <div className="dt-entry-content">
	    	{article.content}
	    </div>

	    <footer className="dt-entry-footer">
	      <div className="dt-entry-more">
	      	<a href={article_path} className="dt-read-more">Read More</a>
	      </div>

	    </footer>
	  </article>
  );
}

export default Article
